<template>
    <b-row class="mx-2 my-2" align-self="center">
        <div v-for="(step, index) in buildSteps" :key="step.id" class="">
            <b-button
                size="sm"
                :pressed="step.id == latestStep.id"
                class="mb-2 text-uppercase"
                @click="$emit('update:currentStepId', step.id)"
                :variant="resolveResult(step.result)"
            >
                <ph-list-checks
                    v-if="step.name == 'synthesis' || step.name == 'synthesize'"
                    size="20"
                />
                <ph-cpu v-else-if="step.name == 'ip'" size="20" />
                <ph-path v-else-if="step.name == 'route'" size="20" />
                <ph-notebook v-else-if="step.name == 'reports'" size="20" />
                <ph-package v-else-if="step.name == 'bitstream'" size="20" />
                {{ step.name }}
            </b-button>
            <ph-arrow-right
                v-if="index < buildSteps.length - 1"
                size="20"
                class="mx-2 mb-2"
                variant="secondary"
                style="vertical-align: middle"
            ></ph-arrow-right>
        </div>
    </b-row>
</template>

<script>
import {
    PhArrowRight,
    PhListChecks,
    PhPath,
    PhNotebook,
    PhPackage,
    PhCpu,
} from 'phosphor-vue'
export default {
    name: 'BuildSteps',
    components: {
        PhArrowRight,
        PhCpu,
        PhListChecks,
        PhPath,
        PhNotebook,
        PhPackage,
    },
    props: {
        buildSteps: null,
    },
    computed: {
        latestStep() {
            if (this.buildSteps && this.buildSteps.length)
                return this.buildSteps[this.buildSteps.length - 1]
            return null
        },
    },
    watch: {
        buildSteps: function () {},
        latestStep() {
            if (this.latestStep)
                this.$emit('update:currentStepId', this.latestStep.id)
        },
    },
    methods: {
        resolveResult: function (result) {
            if (result == 'SUCCESS') {
                return 'success'
            } else if (result == 'FAILURE') {
                return 'danger'
            } else {
                return 'warning'
            }
        },
    },
}
</script>
