<template>
    <Main>
        <template v-slot:nav-left>
            <div class="d-flex align-items-center h-100 pl-2 p-1">
                <span class="color-white"> Projects&nbsp;/&nbsp; </span>
                <input
                    type="text"
                    v-model.lazy="projectName"
                    class="bg-transparent color-white"
                />
            </div>
        </template>

        <template v-slot:nav-right>
            <div class="border-left border-right">
                <b-button
                    @click="saveFile()"
                    variant="transparent"
                    class="d-flex align-items-center color-white"
                    :disabled="!fileOpen"
                >
                    <ph-floppy-disk size="20"/>
                    <span class="ml-2"> Save </span>
                </b-button>
            </div>
            <div class="border-left border-right">
                <b-button
                    @click="buildProject()"
                    variant="transparent"
                    class="d-flex align-items-center color-white"
                >
                    <ph-wrench size="20"/>
                    <span class="ml-2">Build</span>
                </b-button>
            </div>
        </template>

        <b-overlay :show="loading" class="h-100 d-flex flex-column">
            <b-row no-gutters class="h-100">
                <file-explorer ref="refFileExplorer" />
                <b-col class="d-flex flex-column p-0" no-gutters>
                    <div v-if="!fileOpen" class="bg-light project-empty">
                        <div class="project-empty__icon">
                            <ph-folder
                                class="text-secondary"
                                size="64"
                            />
                        </div>
                        <span v-if="filesExist" class="project-empty__content">
                            Click on a file and start coding
                        </span>
                        <span v-else class="project-empty__content">
                            Hi there, it seems like your project<br />is empty.
                            Start by creating a new file.
                        </span>
                        <div class="project-empty__quick_tips">
                            <h6>QUICK TIPS</h6>
                            <table>
                                <tr>
                                    <td>Quick Save</td>
                                    <td>Command + S</td>
                                </tr>
                                <tr>
                                    <td>Save &amp; Build</td>
                                    <td>F8</td>
                                </tr>
                                <tr>
                                    <td>Indent Less</td>
                                    <td>Shift-Tab</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <file-tabs v-else @close="closeConfirm" />

                    <file-editor ref="fileEditorRef" v-if="fileOpen" />
                    <div class="border-top">
                        <Accordion
                            title="Build logs"
                            bodyClass="logs-body"
                            textClass="p-0"
                        >
                            <BuildProgress
                                v-if="latestBuild"
                                :buildId="latestBuild.id"
                                v-on:buildStatusChanged="reloadLatestBuild"
                            />
                        </Accordion>
                    </div>
                </b-col>
            </b-row>
        </b-overlay>

        <b-modal
            centered
            id="save-changes-modal"
            size="sm"
            title="New File"
            hide-header
            hide-footer
            body-class="text-center"
        >
            <template #default="{ hide }">
                <div class="d-flex justify-content-center flex-column">
                    <div class="d-flex justify-content-center">
                        <div class="p-4 rounded-circle bg-gray-200">
                            <ph-pencil-line
                                class="text-muted"
                                style="height: 5rem; width: 5rem"
                            />
                        </div>
                    </div>
                    <h5 class="font-weight-bold mt-3">
                        Do you want to save the changes to
                        {{ formFile.name }}?
                    </h5>
                    <p>Your changes will be lost if you don't save them.</p>

                    <div class="d-flex flex-column" style="row-gap: 0.5rem">
                        <b-button
                            variant="primary"
                            @click="
                                {
                                    $refs.fileEditorRef.save(formFile)
                                    closeFile()
                                    hide()
                                }
                            "
                            >Save</b-button
                        >
                        <b-button
                            @click="
                                {
                                    $refs.fileEditorRef.discard(formFile)
                                    closeFile()
                                    hide()
                                }
                            "
                            >Don't Save</b-button
                        >
                        <b-button class="mt-2" @click="hide">Cancel</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </Main>
</template>

<script>
import { PhFloppyDisk, PhWrench, PhFolder, PhPencilLine } from 'phosphor-vue'
import Main from '../layouts/Main.vue'
import FileEditor from '../components/editor/FileEditor'
import FileExplorer from '../components/editor/FileExplorer'
import FileTabs from '../components/editor/FileTabs'
import createToast from '../mixins/toast'
import loading from '../mixins/loading'
import BuildProgress from '../components/BuildProgress'
import Accordion from '../components/Accordion.vue'

import { formatDateTime } from '../../util/util'

export default {
    name: 'Editor',
    components: {
        FileEditor,
        Main,
        FileExplorer,
        FileTabs,
        PhWrench,
        PhFloppyDisk,
        PhFolder,
        PhPencilLine,
        BuildProgress,
        Accordion,
    },
    mixins: [createToast, loading],
    data() {
        return {
            formFile: null,
        }
    },
    computed: {
        fileOpen() {
            return Boolean(this.$store.state.activeFile)
        },
        filesExist() {
            return Object.keys(this.$store.state.projectFiles).length
        },
        projectName: {
            get() {
                if (this.$store.state.openProject)
                    return this.$store.state.openProject.name
                return ''
            },
            set(name) {
                let p = this.$store.state.openProject
                p.name = name
                this.$store.dispatch('renameProject', p)
            },
        },
        latestBuild: {
            get() {
                return this.$store.state.latestBuild
            },
            set(data) {
                this.$store.dispatch('updateLatestBuild', data)
            },
        },
        dirtyFiles() {
            return this.$store.state.dirtyFiles
        },
    },
    methods: {
        openProject() {
            this.showLoading()
            this.$store
                .dispatch('openProject', this.$route.params.project_id)
                .then(() => this.hideLoading())
        },
        closeConfirm(file) {
            this.formFile = file
            if (this.dirtyFiles.includes(file.id)) {
                this.$bvModal.show('save-changes-modal')
            } else {
                this.closeFile(file)
            }
        },
        closeFile() {
            this.$store.dispatch('closeFile', this.formFile)
        },
        saveFile() {
            this.$refs.fileEditorRef.save(this.$store.state.activeFile)
        },
        validateBuildAction() {
            if (!Object.keys(this.$store.state.projectFiles).length) {
                this.createToast(
                    'Cannot build an empty project, please create a file',
                    'danger'
                )
                return false
            }
            if(!this.$refs.refFileExplorer.validateBuildProperties()){
                this.createToast(
                    'Cannot build a project without configuring the build properties',
                    'danger'
                )
                return false
            }
            return true
        },
        async buildProject() {
            if (this.validateBuildAction()) {
                this.$store
                    .dispatch('buildProject')
                    .then(() =>
                        this.createToast('Build queued successfully', 'success')
                    )
                    .catch((e) => {
                        if (e.response.status == 500) {
                            this.createToast(
                                'Error occurred when requesting a new build',
                                'danger'
                            )
                        } else {
                            this.createToast(e.response.data.detail, 'danger')
                        }
                    })
            }
        },
        reloadLatestBuild() {
            this.$store.dispatch('updateLatestBuild')
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.openProject()
        })
    },
    beforeRouteUpdate(to, from, next) {
        this.openProject()
        next()
    },
    formatDateTime: formatDateTime,
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

input {
    background-color: transparent;
    border-color: transparent;
}

ul {
    padding: 0;
    list-style: none;
    display: flex;
    row-gap: 2rem;
    flex-direction: column;
}

.row {
    flex-wrap: nowrap;
}

.col {
    min-width: 10px;
}

.project-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
}

.project-empty__icon {
    background-color: $gray-200;
    padding: 2rem;
    border-radius: 50%;
}

.project-empty__content {
    margin-top: 2rem;
    line-height: 1.75rem;
    color: $text-muted;
}

.project-empty__quick_tips {
    margin-top: 2rem;

    h6 {
        font-weight: bold;
        letter-spacing: 1.5px;
    }

    table {
        border-collapse: separate;
        border-spacing: 1rem;
    }

    table td {
        padding: 0.25rem 0.4rem;
        text-align: left;
        color: var(--secondary);

        &:last-child {
            font-family: 'IBM Plex Mono';
            background-color: $gray-200;
            border-radius: 2px;
        }
    }
}
</style>
